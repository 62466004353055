import Constants from "./constants";
import * as microsoftTeams from "@microsoft/teams-js";
import { environment } from "../environments/environment";
import { IHelpItem, IInlineHelpSettings, IBannerSettings, IMediaSettings } from "../models/helpItemModel";
import { IUser } from "../models/userModel";
import PostMessageCommand from "./postMessageCommand";
import $ from "jquery";

// tslint:disable:max-line-length
export default class Utils {
    public static convertLegacyUserStorage(value: string): string {
        if (value == null || value.length === 0) { return value; }
        return value
            .replace(/"CustomH"/g, '"C"')
            .replace(/"CustomHelpItemId"/g, '"C"')
            .replace(/"HelpItemId"/g, '"H"')
            .replace(/"LastLoadedDate"/g, '"L"')
            .replace(/"LastViewedDate"/g, '"V"')
            .replace(/"LCFromDate"/g, '"D"')
            .replace(/"LoadCountFromDate"/g, '"D"')
            .replace(/"LoadCount"/g, '"LC"')
            .replace(/"LastAutoShowOnViewAfter"/g, '"A"')
            .replace(/"BlockedOn"/g, '"B"')
            .replace(/"00000000-0000-0000-0000-000000000000"/g, '""');
    }

    /**
     * Sets the JSON properties so that we can work with a complex type instead of the string representation.
     * This routine is called whenever a new help item is loaded (getHelpItemsBySite, importHelpItem, addHelpItem).
     * @param helpItem The help item to update.
     */
    public static setHelpItemJSONProperties(helpItem: IHelpItem): void {
        if (helpItem == null) { return; }
        // Sets the UrlFilterJSON property so that we can work with a complex type instead of the string representation.
        // Also handles the legacy version of this which was just the regular expression and not a JSON object.
        // This routine is called whenever a new help item is loaded (getHelpItemsBySite, importHelpItem, addHelpItem).
        if (helpItem.UrlFilter == null || helpItem.UrlFilter.length === 0) {
            helpItem.UrlFilterJSON = [{ filterType: 'UrlStartsWith', filterValue: '' }];
        } else if (helpItem.UrlFilter !== '[]' && !Utils.startsWith(helpItem.UrlFilter, '[{')) {
            // Legacy - change the string to a JSON string
            helpItem.UrlFilterJSON = [{ filterType: 'UrlRegex', filterValue: helpItem.UrlFilter }];
        } else {
            helpItem.UrlFilterJSON = JSON.parse(helpItem.UrlFilter);
        }
        // Reset the UrlFilter property so that we can use it for comparison checks in the has changes method.
        helpItem.UrlFilter = JSON.stringify(helpItem.UrlFilterJSON);

        // Sets the InlineHelpSettingsJSON property so that we can work with a complex type instead of the string representation.
        // This routine is called whenever a new help item is loaded (getHelpItemsBySite, importHelpItem, addHelpItem).
        if (helpItem.InlineHelpSettings == null || helpItem.InlineHelpSettings.length === 0) {
            helpItem.InlineHelpSettingsJSON = {
                ShowInMainHelpPanel: false,
                Trigger: 'click',
                IsTooltip: true,
                Position: 'right',
                CustomIconBGColor: 'rgba(198, 198, 198, 1)',
                CustomIconColor: 'rgba(255, 255, 255, 1)',
                CustomIconText: '?',
                UseCustomIcon: false
            };
            helpItem.InlineHelpSettings = JSON.stringify(helpItem.InlineHelpSettingsJSON);
        } else {
            helpItem.InlineHelpSettingsJSON = JSON.parse(helpItem.InlineHelpSettings) as IInlineHelpSettings;
            if (Utils.isNullOrEmpty(helpItem.InlineHelpSettingsJSON.CustomIconBGColor)) {
                helpItem.InlineHelpSettingsJSON.CustomIconBGColor = 'rgba(198, 198, 198, 1)';
            }
            if (Utils.isNullOrEmpty(helpItem.InlineHelpSettingsJSON.CustomIconColor)) {
                helpItem.InlineHelpSettingsJSON.CustomIconColor = 'rgba(255, 255, 255, 1)';
            }
            if (Utils.isNullOrEmpty(helpItem.InlineHelpSettingsJSON.CustomIconText)) {
                helpItem.InlineHelpSettingsJSON.CustomIconText = '?';
            }
        }
        // Reset the UrlFilter property so that we can use it for comparison checks in the has changes method.
        helpItem.InlineHelpSettings = JSON.stringify(helpItem.InlineHelpSettingsJSON);
        const defaultBanner: IBannerSettings = {
            Position: 'top', FGColor: 'rgba(0, 0, 0, 1)', BGColor: 'rgba(253, 243, 213, 1)', ShowInMainHelpPanel: true
        };
        if (helpItem.MediaSettings != null) {
            helpItem.MediaSettingsJSON = JSON.parse(helpItem.MediaSettings) as IMediaSettings;
            if (helpItem.MediaSettingsJSON.Banner == null) { helpItem.MediaSettingsJSON.Banner = defaultBanner; }
        } else {
            helpItem.MediaSettingsJSON = {
                Banner: defaultBanner
            };
        }
        // Reset the MediaSettings property so that we can use it for comparison checks in the has changes method.
        helpItem.MediaSettings = JSON.stringify(helpItem.MediaSettingsJSON);
    }
    
    public static getUserId(win: Window) {
        return new Promise<string>(resolve => {
            let userId: string|null|undefined = Utils.getParameterByNameFromUrl('userId', win.location.href);
            if (userId == null || Utils.isNullOrEmpty(userId) || userId === Constants.EmptyGuid || userId.toUpperCase() === Constants.AnonymousUserId) {
                userId = Utils.getLocalStorage(win, 'VisualSPOnlineHelpApp_UserId');
                if (userId != null && !Utils.isNullOrEmpty(userId) && userId !== Constants.EmptyGuid && userId.toUpperCase() !== Constants.AnonymousUserId) {
                    this.setUserId(win, userId);
                    return resolve(userId);
                } else {
                    let windowTop = win.top;
                    if (windowTop != null && windowTop.opener != null) {
                        windowTop = (windowTop.opener as Window).top;
                    }                        
                    if (windowTop == null) { return resolve(Constants.EmptyGuid); }
                    Utils.sendMessageToWindow(windowTop, win, 'GetUserId', null, 'GetUserIdResponse', 500)
                        .then((data: any) => {
                            if (Utils.isNullOrEmpty(data)) {
                                userId = Constants.EmptyGuid;
                            } else {
                                userId = data;
                                this.setUserId(win, userId as string);
                            }
                            return resolve(userId as string);
                        });
                }
            } else {
                if (Utils.isNull(userId)) {
                    userId = Constants.EmptyGuid;
                }
                return resolve(userId);
            }
        });
    }
    public static setUserId(win: Window, userId: string) {
        // Add as a fallback to local storage.
        Utils.setLocalStorage(win, 'VisualSPOnlineHelpApp_UserId', userId);
        // Store the user ID in permanent storage.
        Utils.sendMessageToWindow(win.parent, win, 'SetUserId', userId);
    }

        /**
     * Sends a message from the source window to the specified target window.
     * Do not use a Promise object as that would require a polyfill for IE.
     * @param targetWindow The target window to send the message to.
     * @param sourceWindow The source window where the message originates.
     * @param command The command to send to the target window.
     * @param data The data to send to the target window.
     * @param callbackCommand? A callback function to run when the target window responds to the message.
     * @param callbackTimeout? A callback timeout.
     * @returns A JQueryPromise object with any data received from the target window as a result of the message.
     */
    public static sendMessageToWindow(targetWindow: Window, sourceWindow: Window,
        command: string, data: any, callbackCommand?: string, callbackTimeout?: number): JQueryDeferred<any> {
        const deferred: JQueryDeferred<any> = $.Deferred<any>();
        if (this.isNull(targetWindow)) {
            return deferred.resolve(null);
        }
        if (!this.isNull(callbackCommand)) {
            let callbackWaitTime = 0;
            const callbackIntervalId = sourceWindow.setInterval(() => {
                callbackWaitTime += 50;
                if (callbackWaitTime >= (callbackTimeout || 0)) {
                    sourceWindow.clearInterval(callbackIntervalId);
                    return deferred.resolve(null);
                }
            }, 50);
            $(sourceWindow).off('message.visualSPAsyncMessage' + callbackCommand + callbackIntervalId);
            $(sourceWindow).on('message.visualSPAsyncMessage' + callbackCommand + callbackIntervalId, (e: JQueryEventObject) => {
                const data1: PostMessageCommand|null = PostMessageCommand.getMessageFromMessageEvent(e.originalEvent as MessageEvent);
                if (data1 == null) {
                    return deferred.resolve(null);
                }
                if (this.isEqual(data1.command, callbackCommand)) {
                    sourceWindow.clearInterval(callbackIntervalId);
                    $(sourceWindow).off('message.visualSPAsyncMessage' + callbackCommand + callbackIntervalId);
                    return deferred.resolve(data1.data);
                }
            });
        }
        PostMessageCommand.postMessage(targetWindow, command, data, sourceWindow.location.href);

        if (this.isNull(callbackCommand)) {
            return deferred.resolve(null);
        }
        return deferred;
    }

    /**
     * If the object is null or has a length of zero then return true, else return false.
     * @param value The value to check.
     * @returns true or false if empty or not empty
     */
    public static isNullOrEmpty(value: any) {
        return value === null || value === undefined || value.length === 0;
    }

    /**
     * Checks if the value is null or undefined.
     * @param value The value to check.
     * @returns True if null or undefined.
     */
    public static isNull(value: any) {
        return value === null || value === undefined;
    }

    /**
     * Checks if each value is equal. Considers null and undefined to be equal. Not necessary to use if one of the values is a static value or known not to be null or undefined.
     * @param val1 THe first value to check.
     * @param val2 The second value to check.
     * @returns True if equal.
     */
    public static isEqual(val1: any, val2: any) {
        if (val1 === undefined) {
            val1 = null;
        }
        if (val2 === undefined) {
            val2 = null;
        }
        if (val1 === null && val2 === null) {
            return true;
        }
        return val1 === val2;
    }

    /**
     * Save an item to the localStorage object.
     * @param win The window to save the value to.
     * @param key The unique identifier for the data to save.
     * @param value The value to save.
     */
    public static setLocalStorage(win: Window, key: string, value: string) {
        if (this.isNull(win)) {
            win = window;
        }
        try {
            if (this.isNullOrEmpty(value)) {
                win.localStorage.removeItem(key);
            } else {
                win.localStorage.setItem(key, value);
            }
        } catch {
            console.error("VisualSP is unable to set localStorage. Make sure third party cookies are not blocked. (" + key + ")");
        }
    }

    /**
     * Save an item to the sessionStorage object.
     * @param win The window to save the value to.
     * @param key The unique identifier for the data to save.
     * @param value The value to save.
     */
    public static setSessionStorage(win: Window, key: string, value: string) {
        if (this.isNull(win)) {
            win = window;
        }
        try {
            if (this.isNullOrEmpty(value)) {
                win.sessionStorage.removeItem(key);
            } else {
                win.sessionStorage.setItem(key, value);
            }
        } catch {
            console.error("VisualSP is unable to set sessionStorage. Make sure third party cookies are not blocked. (" + key + ")");
        }
    }

    /**
     * Get an item from localStorage.
     * @param win The window to pull the data from.
     * @param key The unique identifier of the data to retrieve.
     * @returns A string value.
     */
    public static getLocalStorage(win: Window, key: string): string|null|undefined {
        if (this.isNull(win)) {
            win = window;
        }
        let item;
        try {
            item = win.localStorage.getItem(key);
        } catch {
            console.error("VisualSP is unable to read localStorage. Make sure third party cookies are not blocked. (" + key + ")");
        }
        return item;
    }

    /**
     * Get an item from sessionStorage.
     * @param win The window to pull the data from.
     * @param key The unique identifier of the data to retrieve.
     * @returns A string value.
     */
    public static getSessionStorage(win: Window, key: string): string|null|undefined {
        if (this.isNull(win)) {
            win = window;
        }
        let item;
        try {
            item = win.sessionStorage.getItem(key);
        } catch {
            console.error("VisualSP is unable to read sessionStorage. Make sure third party cookies are not blocked. (" + key + ")");
        }
        return item;
    }

    /**
     * Remove all entries from localStorage that begin with the specified key value.
     * @param win The window to clear the data from.
     * @param key The key to search for to clear (clears all data that starts with the specified key value).
     */
    public static clearLocalStorage(win: Window, key: string) {
        if (this.isNull(win)) {
            win = window;
        }
        const toRemove: string[] = [];
        try {
            for (let i = 0; i < win.localStorage.length; i++) {
                const lskey = win.localStorage.key(i);
                if (lskey == null) { continue; }
                if (Utils.startsWith(lskey, key)) {
                    toRemove.push(lskey);
                }
            }
            // tslint:disable-next-line:prefer-for-of
            for (let i = 0; i < toRemove.length; i++) {
                win.localStorage.removeItem(toRemove[i]);
            }
        } catch {
            console.error("VisualSP is unable to clear localStorage. Make sure third party cookies are not blocked. (" + key + ")");
        }
    }

    /**
     * Remove all entries from sessionStorage that begin with the specified key value.
     * @param win The window to clear the data from.
     * @param key The key to search for to clear (clears all data that starts with the specified key value).
     */
    public static clearSessionStorage(win: Window, key: string) {
        if (this.isNull(win)) {
            win = window;
        }
        const toRemove: string[] = [];
        try {
            for (let i = 0; i < win.sessionStorage.length; i++) {
                const lskey = win.sessionStorage.key(i);
                if (lskey == null) { continue; }
                if (Utils.startsWith(lskey, key)) {
                    toRemove.push(lskey);
                }
            }
            // tslint:disable-next-line:prefer-for-of
            for (let i = 0; i < toRemove.length; i++) {
                win.sessionStorage.removeItem(toRemove[i]);
            }
        } catch {
            console.error("VisualSP is unable to clear sessionStorage. Make sure third party cookies are not blocked. (" + key + ")");
        }
    }

    public static readCookie(win: Window, name: string): string|null|undefined {
        const nameEQ: string = name + '=';
        const ca: string[] = win.document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c: string = ca[i];
            while (c.charAt(0) === ' ') { c = c.substring(1, c.length); }
            if (c.indexOf(nameEQ) === 0) { return c.substring(nameEQ.length, c.length); }
        }
        // Check localstorage as a fallback
        return this.getLocalStorage(win, name);
    }
    public static createCookie(win: Window, name: string, value: string, days?: number) {
        let expires = "";
        if (!days) {
            days = 5 * 365;
        }
        if (this.isNullOrEmpty(value)) {
            days = -1;
        }
        const domain = (win.location.hostname && win.location.hostname !== "localhost") ? "; domain=" + (win.location.host) : "";
        const date: Date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = domain + "; expires=" + date.toUTCString();
        document.cookie = name + "=" + value + expires + "; SameSite=None; Secure; path=/";
    }

    public static getParameterByName(win: Window, name: string) {
        const isInIFrame = (window.parent !== win);
        let parentUrl = win.location.href;
        name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
        const regexS = "[\\?&]" + name + "=([^&#]*)";
        const regex = new RegExp(regexS);
        let results = regex.exec(parentUrl);
        if (results == null || this.isNullOrEmpty(results) || results[1].replace(/ /g, " ") === "") {
            if (isInIFrame) {
                parentUrl = document.referrer;
            } else {
                return "";
            }
            results = regex.exec(parentUrl);
            if (results == null || this.isNullOrEmpty(results)) {
                return "";
            } else {
                return decodeURIComponent(results[1].replace(/ /g, " "));
            }
        } else {
            return decodeURIComponent(results[1].replace(/ /g, " "));
        }
    }
    public static getParameterByNameFromUrl(name: string, url: string) {
        name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
        const regexS = "[\\?&]" + name + "=([^&#]*)";
        const regex = new RegExp(regexS);
        const results = regex.exec(url);
        if (results == null || this.isNullOrEmpty(results) || results[1].replace(/ /g, " ") === "") {
            return "";
        } else {
            return decodeURIComponent(results[1].replace(/ /g, " "));
        }
    }

    public static getErrorMessage(error: any) {
        let message: string = error._body || error;
        if (message != null && typeof(message.indexOf) === "undefined" && error.ok === false) {
            if (error.status === 0) {
                return "An unknown network error has occurred. Please verify your network connection.";
            } else if (error.status === 404) {
                return ("The requested page not found. [404]");
            } else if (error.status === 500) {
                return ("Internal Server Error [500].");
            }
            if (typeof(error.statusText) === "string") {
                return error.statusText;
            }
            if (typeof(error.responseText) === "string") {
                return error.responseText;
            }
        }
        if (message != null && typeof(message.indexOf) !== "undefined" && message.indexOf('{"Message":') >= 0) {
            try {
                const json = JSON.parse(message);
                message = `${json.Message} ${json.ExceptionMessage}`;
            } catch {
                return message;
            }
        }
        return message;
    }

    public static stringify(inputObject: any): string {
        const seenObjects: any = [];
        function inspectElement(key: any, value: any) {
            if (detectCycle(value)) {
                return "[Cyclical]";
            } else {
                return value;
            }
        }
        function detectCycle(obj: any): boolean {
            if (obj && (typeof obj === "object")) {
                for (const r of seenObjects) {
                    if (r === obj) {
                        return true;
                    }
                }
                seenObjects.push(obj);
            }
            return false;
        }
        const json: string = JSON.stringify(inputObject, inspectElement);
        return json;
    }

    public static startsWith(value: string|undefined, searchString: string, position?: number): boolean {
        if (value == null) { return false; }
        position = position || 0;
        return value.substr(position, searchString.length) === searchString;
    }

    public static isSafari(): boolean {
        return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    }
    public static isInternetExplorer(): boolean {
        const v = this.getInternetExplorerVersion();
        return v > 0 && v < 12;
    }
    public static getInternetExplorerVersion(): number {
        const ua = window.navigator.userAgent;

        const msie = ua.indexOf("MSIE ");
        if (msie > 0) {
            // IE 10 or older => return version number
            return parseInt(ua.substring(msie + 5, ua.indexOf(".", msie)), 10);
        }

        const trident = ua.indexOf("Trident/");
        if (trident > 0) {
            // IE 11 => return version number
            const rv = ua.indexOf("rv:");
            return parseInt(ua.substring(rv + 3, ua.indexOf(".", rv)), 10);
        }

        const edge = ua.indexOf("Edge/");
        if (edge > 0) {
            // Edge (IE 12+) => return version number
            return parseInt(ua.substring(edge + 5, ua.indexOf(".", edge)), 10);
        }

        // other browser
        return -1;
    }
    public static isNumber(value: any): boolean {
        if (value == null) { return false; }
        const toString = Object.prototype.toString;
        return typeof value === "number" ||
            (!!value && typeof value === "object" && toString.call(value) === "[object Number]") ||
            parseInt(value, 10).toString() === value.toString();
    }

    public static getTextWidth(text?: string, font?: string): number {
        // re-use canvas object for better performance
        if (text == null) { return 0; }
        const canvas = this._canvas || (this._canvas = document.createElement("canvas"));
        const context = canvas.getContext("2d");
        if (context == null) { return 0; }
        if (font != null) {
            context.font = font;
        }
        const metrics = context.measureText(text);
        return metrics.width;
    }


    /**
     * Add Google Analytics data to the provided URL.
     * @param url The URL to update.
     * @param userId The user ID of the current user.
     * @param subscriptionId The subscription (organization) ID of the current user.
     * @returns The modified URL.
     */
    public static addGAData(user: IUser|undefined|null, context: microsoftTeams.app.Context|undefined, url: string): string {
        if (url.indexOf('?') < 0) { url += '?'; }
        if (user?.UserId != null) {
            url += "&UserId=" + encodeURIComponent(user.UserId);
        }
        if (user?.OrganizationId != null) {
            url += "&SubscriptionId=" + encodeURIComponent(user.OrganizationId);
        }
        url += "&sitescope=" + encodeURIComponent("teams");
        if (context != null && context.channel?.displayName != null) {
            url += "&source=" + encodeURIComponent(context.channel.displayName);
        }
        return url;
    }

    public static getUrl(user: IUser|undefined|null, context: microsoftTeams.app.Context|undefined, helpItem: IHelpItem, organizationId: string) {
        const userId = user == null ? Constants.AnonymousUserId : user.UserId;
        if (organizationId == null || organizationId === Constants.EmptyGuid) {
            organizationId = user == null ? Constants.DefaultOrganizationId : user.OrganizationId as string;
        }
        if (helpItem.Viewer.toLowerCase() === "document" || helpItem.Viewer.toLowerCase() === "webpage") {
            let url = helpItem.Media as string;
            url = this.replaceTokens(this.addGAData(user, context, url));
            return url;
        } else {
            return `${Constants.ContentMainRoot}media?userId=${userId}&organizationId=${organizationId}&helpItemId=${helpItem.HelpItemId}&customHelpItemId=${helpItem.CustomHelpItemId}&source=${encodeURIComponent(window.location.href)}&sitescope=teams&v=${this.build}`;
        }
    }
    public static replaceTokens(url: string): string {
        if (url.toLowerCase().indexOf("/docs.microsoft.com/") > 0 && url.toLowerCase().indexOf("fromOrigin=") < 0) {
            if (url.indexOf("?") < 0) { url += "?"; }
            url += "&fromOrigin=" + window.location.origin;
        }
        url = url.replace(/{origin}/g, encodeURIComponent(window.location.origin))
                 .replace(/{host}/g, encodeURIComponent(window.location.host))
                 .replace(/{source}/g, encodeURIComponent(window.location.href));
        return url;
    }

    public static getValidOrigins(): string[] {
        var origins =  ['https://static.contextall.com', 'https://static-stage.contextall.com', 'https://api.contextall.com', 'https://api-stage.contextall.com', 'https://teams.contextall.com', 'https://teams-stage.contextall.com'];
        if (origins.indexOf(window.location.origin) < 0) {
            origins.push(window.location.origin);
        }
        return origins;
    }

    // tslint:disable-next-line:variable-name
    private static _canvas: HTMLCanvasElement|undefined = undefined;
    private static build: string = environment.build + "." + new Date(new Date().toDateString()).valueOf();
}
