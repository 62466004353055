import { EnvironmentBase } from "./environment-base";

// tslint:disable:no-inferrable-types
class Environment extends EnvironmentBase {
    public production: boolean = false;
    public stage: boolean = true;
    public dev: boolean = false;
    public cdnHost: string = 'https://static-stage.contextall.com/';
    public apiHost: string = 'https://api-stage.contextall.com/';
    public MICROSOFT_APP_ID: string = "0f7d4108-9d3a-422a-adb7-7d10d8668e2a";
}
export const environment = new Environment();
