import { environment } from "../environments/environment";
export default class Constants {
    public static get ContentServiceAPI(): string {
        return environment.apiHost + 'api/';
    }
    public static get ContentHostHost(): string {
        return environment.cdnHost;
    }
    public static get ContentHostRoot(): string {
        return Constants.ContentHostHost + 'app/';
    }

    public static get ContentMainHost(): string {
        return environment.cdnHost.replace('8082', '8080');
    }
    public static get ContentMainRoot(): string {
        return Constants.ContentMainHost + 'app/';
    }

    public static get ContentManagementHost(): string {
        return environment.cdnHost.replace('8082', '8081');
    }
    public static get ContentManagementRoot(): string {
        return Constants.ContentManagementHost + "management/";
    }

    public static get HostScriptRoot(): string { return Constants.ContentHostRoot + "js/"; }
    public static get HostScriptLibRoot(): string { return Constants.ContentMainRoot + "lib/"; }
    public static get HostCssLibRoot(): string { return Constants.ContentHostRoot + "css/"; }
    public static get HostImagesRoot(): string { return Constants.ContentHostRoot + "images/"; }
    public static get EmptyGuid(): string { return "00000000-0000-0000-0000-000000000000"; }
    public static get DefaultOrganizationId(): string { return "10000000-0000-0000-A51F-000000000001"; }
    public static get DefaultAnonymousOrganizationId(): string { return "10000000-0000-0000-A51F-000000000004"; }
    public static get DefaultPaidOrganizationId(): string { return "10000000-0000-0000-A51F-000000000008"; }
    public static get AnonymousUserId(): string { return "30000000-0000-0000-A51F-000000000003"; }

    public static get AppTitle(): string { return "VisualSP Online Help System"; }
    public static get HandleOffsetDefault(): number { return 97.5; }
    public static get HandleDockPositionDefault(): "top" | "bottom" | "left" | "right" { return "bottom"; }
    public static get HandleTextDefault(): string { return "Need Help?"; }

    // System Identifiers for known help content.
    public static get SystemHelpItem_GoldLockHelpItemLink(): string { return "GoldLockHelpItemLink"; }
    public static get SystemHelpItem_ManageMySubscriptionMainAppMenuLink(): string { return "ManageMySubscriptionMainAppMenuLink"; }
    public static get SystemHelpItem_ViewAnalyticsMainAppMenuLink(): string { return "ViewAnalyticsMainAppMenuLink"; }
    public static get SystemHelpItem_EnableEditModeMainAppMenuLink(): string { return "EnableEditModeMainAppMenuLink"; }
    public static get SystemHelpItem_EnableAutoActivationLink(): string { return "EnableAutoActivationLink"; }
    public static get SystemHelpItem_GetSharePointOnPremHostName(): string { return "GetSharePointOnPremHostName"; }
    public static get SystemHelpItem_GetSharePointOnlineHostName(): string { return "GetSharePointOnlineHostName"; }
    public static get SystemHelpItem_GetO365Realm(): string { return "GetO365Realm"; }
    public static get SystemHelpItem_GeneralMainAppMenuLink(): string { return "GeneralMainAppMenuLink"; }
    public static get SystemHelpItem_PermissionsOverview(): string { return "PermissionsOverview"; }
    public static get SystemHelpItem_ManageUsers(): string { return "ManageUsers"; }
    public static get SystemHelpItem_ManageAppScopes(): string { return "ManageAppScopes"; }

    // System Help Item Identifiers - Walkthroughs
    public static get SystemHelpItem_Walkthru_TargetElement(): string { return "Walkthru_TargetElement"; }
    public static get SystemHelpItem_Walkthru_ShowCallToActionButton(): string { return "Walkthru_ShowCallToActionButton"; }
    public static get SystemHelpItem_Walkthru_AdvanceWhenTargetClicked(): string { return "Walkthru_AdvanceWhenTargetClicked"; }
    public static get SystemHelpItem_Walkthru_ShowWhenTargetClicked(): string { return "Walkthru_ShowWhenTargetClicked"; }
    public static get SystemHelpItem_Walkthru_NextStepIsOnANewPage(): string { return "Walkthru_NextStepIsOnANewPage"; }
    public static get SystemHelpItem_Walkthru_Delay(): string { return "Walkthru_Delay"; }
    public static get SystemHelpItem_Walkthru_ZIndex(): string { return "Walkthru_ZIndex"; }
    public static get SystemHelpItem_Walkthru_SelectAnEventToEdit(): string { return "Walkthru_SelectAnEventToEdit"; }
    public static get SystemHelpItem_Walkthru_AddActivityToAnEvent(): string { return "Walkthru_AddActivityToAnEvent"; }


    // Analytics data fields
    public static get Dimension_Version(): string { return "dimension1"; }
    public static get Dimension_ClientId(): string { return "dimension2"; }
    public static get Dimension_UserId(): string { return "dimension3"; }
    public static get Dimension_SubscriptionId(): string { return "dimension4"; }
    public static get Dimension_Extension(): string { return "dimension5"; }
    public static get Dimension_SiteScope(): string { return "dimension6"; }
    public static get Dimension_ExtensionVersion(): string { return 'dimension7'; }
    public static Data: any = null;

    public static get AnalyticsAction() {
        return {
            HelpItemClick: 0, TabClick: 1, InlineHelpHover: 2, InlineHelpClick: 3, Search: 4,
            properties: {
                0: { name: "HelpItemClick", value: 0 },
                1: { name: "TabClick", value: 1 },
                2: { name: "InlineHelpHover", value: 2 },
                3: { name: "InlineHelpClick", value: 3 },
                4: { name: "Search", value: 4 }
            }
        };
    }

}
