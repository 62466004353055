import React, { Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import {
    FluentProvider,
    teamsLightTheme,
    teamsDarkTheme,
    teamsHighContrastTheme,
    tokens,
  } from "@fluentui/react-components";
import { TeamsFxContext } from "./shared-components/Context";
import { useEffect } from "react";
import { app } from "@microsoft/teams-js";
import { useTeams } from "@microsoft/teamsfx-react";
// https://github.com/OfficeDev/teams-toolkit-samples/blob/v2.5.0/hello-world-tab-docker/src/components/App.tsx

import './App.scss';
import Home from './components/home/Home';
import { LoadingPanel } from './shared-components/LoadingPanel';
import Utils from './utilities/utils';

const ErrorPage = React.lazy(() => import('./components/error/Error'));
const NotFoundPage = React.lazy(() => import('./components/notFound/NotFound'));

const PrivacyPage = React.lazy(() => import('./components/privacy/Privacy'));

const TOUPage = React.lazy(() => import('./components/tou/TOU'));
const TabHomePage = React.lazy(() => import('./components/tab/tabHome/TabHome'));
const TabConfigPage = React.lazy(() => import('./components/tab/tabConfig/TabConfig'));
const TabRemovePage = React.lazy(() => import('./components/tab/tabRemove/TabRemove'));

const MessageExtensionActionPage = React.lazy(() => import('./components/messageExtension/messageExtensionAction/MessageExtensionActionPage'));
const MessageExtensionConfigPage = React.lazy(() => import('./components/messageExtension/messageExtensionConfig/MessageExtensionConfig'));

export const App: React.FunctionComponent = () => {
    const [{ loading, theme, themeString, inTeams, context }] = useTeams();
      useEffect(() => {
        loading &&
          app.initialize(Utils.getValidOrigins()).then(() => {
            // Hide the loading indicator.
            app.notifySuccess();
          });
      }, [loading]);
    return loading ? <></> : (
        <TeamsFxContext.Provider value={{ theme, themeString, inTeams: inTeams ?? false, context }}>
            <FluentProvider
                theme={
                themeString === "dark"
                    ? teamsDarkTheme
                    : themeString === "contrast"
                    ? teamsHighContrastTheme
                    : {
                        ...teamsLightTheme,
                        colorNeutralBackground3: "#eeeeee",
                    }
                }
                style={{ background: tokens.colorNeutralBackground3 }}
            >                
                <div className="app">
                    <BrowserRouter>
                        <Suspense fallback={<LoadingPanel />}>
                            <Routes>
                                <Route path="/" element={<Home />}></Route>
                                <Route path="/privacy" element={<PrivacyPage />}></Route>
                                <Route path="/tou" element={<TOUPage />}></Route>
                                <Route path="/tab" element={<TabHomePage />}></Route>
                                <Route path="/tab/config" element={<TabConfigPage />}></Route>
                                <Route path="/tab/remove" element={<TabRemovePage />}></Route>
                                <Route path="/messageextension/action" element={<MessageExtensionActionPage />}></Route>
                                <Route path="/messageextension/config" element={<MessageExtensionConfigPage />}></Route>
                                <Route path="/error" element={<ErrorPage />} errorElement={true}></Route>
                                <Route path="*" element={<NotFoundPage />}></Route>
                            </Routes>
                        </Suspense>
                    </BrowserRouter>
                </div>
            </FluentProvider>
        </TeamsFxContext.Provider>
    );
}
