import { app } from "@microsoft/teams-js";
import { createContext } from "react";
import { Theme } from "@fluentui/react-components";

export const TeamsFxContext = createContext<{
    theme?: Theme;
    themeString: string;
    inTeams: boolean;
    context?: app.Context;
}>({
    theme: undefined,
    themeString: "",
    inTeams: false,
    context: undefined
});
