export default class PostMessageCommand {

    public static postMessage(targetWindow: Window, command: string, data: any, source?: string, userId?: string, organizationId?: string) {
        const msg = new PostMessageCommand();
        msg.command = command;
        msg.data = data;
        msg.source = source;
        msg.userId = userId;
        msg.organizationId = organizationId;

        targetWindow.postMessage(msg, "*");
        const ieV: number = this.getInternetExplorerVersion();
        if ((ieV > 0 && ieV <= 11) || command === "GetUserId" || command === "SetUserId") {
            // For the IE extension we have to pass a string, not an object.
            targetWindow.postMessage(JSON.stringify(msg), "*");
        }
    }
    public static getMessageFromMessageEvent(originalEvent: MessageEvent): PostMessageCommand|null {
        let data = originalEvent.data as PostMessageCommand;
        if (data === null || data === undefined || data.owner === null || data.owner === undefined || data.owner !== "VisualSP") {
            try {
                data = JSON.parse(originalEvent.data);
            } catch (e) { }
            if (data === null || data === undefined || data.owner === null || data.owner === undefined || data.owner !== "VisualSP") {
                return null;
            } else {
                return data;
            }
        } else {
            return data;
        }
    }

    /**
     * Need this here to avoid a circular depencency with Utils.
     */
    private static getInternetExplorerVersion(): number {
        const ua = window.navigator.userAgent;

        const msie = ua.indexOf("MSIE ");
        if (msie > 0) {
            // IE 10 or older => return version number
            return parseInt(ua.substring(msie + 5, ua.indexOf(".", msie)), 10);
        }

        const trident = ua.indexOf("Trident/");
        if (trident > 0) {
            // IE 11 => return version number
            const rv = ua.indexOf("rv:");
            return parseInt(ua.substring(rv + 3, ua.indexOf(".", rv)), 10);
        }

        const edge = ua.indexOf("Edge/");
        if (edge > 0) {
            // Edge (IE 12+) => return version number
            return parseInt(ua.substring(edge + 5, ua.indexOf(".", edge)), 10);
        }

        // other browser
        return -1;
    }
    public owner = "VisualSP";
    public command: string | undefined;
    public data: any;
    public source?: string;
    public userId?: string;
    public organizationId?: string;

}
