import * as React from 'react';
import styles from './Home.module.scss';

const Home = () => {
    return (
        <div className={styles.home}>
            <header className="l-header">
                <div className="logo">
                    <img alt="VisualSP" src="https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product-fluent/svg/teams_48x1.svg" className="logo" />
                </div>
                <div className="l-title">
                    <h1>Welcome to the <em>VisualSP Teams App</em></h1>
                </div>
            </header>
            <article className="l-article">
                
            </article>
        </div>
    );
}
export default Home;